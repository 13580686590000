import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Loading from "./components/Loading";

const Builder = lazy(() => import("./pages/commons/BUILDER"));
const Builder_Visio = lazy(() => import("./pages/commons/BUILDER_VISIO"));
const Builder_Residentiel = lazy(() => import("./pages/commons/BUILDER_RESIDENTIEL"));
const WhoAreYou = lazy(() => import("./pages/commons/WHOAREYOU"));
const AUTO_GEO_REDIRECT = lazy(() => import("./components/AutoGeoLocation"));

const Checkout = lazy(() => import("./components/Checkout"));
const CheckoutCancel = lazy(() => import("./components/Checkout/Cancel"));
const CheckoutSuccess = lazy(() => import("./components/Checkout/Success"));
const CheckoutSuccessResidentiel = lazy(() => import("./components/Checkout/SuccessResidentiel"));

export default function App() {
  return <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <Routes>
          {/* NOT FOUNDED PAGE OR ROOT PAGE ! */}
          <Route exact path="/" element={<WhoAreYou />} />
          <Route path="*" element={<WhoAreYou />} />

          <Route path="/visio" element={<Builder_Visio />} />
          <Route path="/fr/visio" element={<Builder_Visio />} />
          <Route path="/be/visio" element={<Builder_Visio />} />
          <Route path="/ma/visio" element={<Builder_Visio />} />
          <Route path="/ca/visio" element={<Builder_Visio />} />
          <Route path="/ch/visio" element={<Builder_Visio />} />
          <Route path="/lu/visio" element={<Builder_Visio />} />
          <Route path="/mc/visio" element={<Builder_Visio />} />

          <Route path="/use-auto-locate" element={<AUTO_GEO_REDIRECT />} />

          <Route path="/checkout">
            <Route index element={<Checkout />} />
            <Route path="success" element={<CheckoutSuccess />} />
            <Route path="residentiel/success" element={<CheckoutSuccessResidentiel />} />
            <Route path="cancel" element={<CheckoutCancel />} />
          </Route>

          <Route path="/fr/institut-adios">
              <Route path="/fr/institut-adios/auvergne-rhone-alpes" element={<Builder resolve="/fr/institut-adios/auvergne-rhone-alpes" />} />
              <Route path="/fr/institut-adios/bourgogne-franche-comte" element={<Builder resolve="/fr/institut-adios/bourgogne-franche-comte" />} />
              <Route path="/fr/institut-adios/bretagne" element={<Builder resolve="/fr/institut-adios/bretagne" />} />
              <Route path="/fr/institut-adios/centre-val-de-loire" element={<Builder resolve="/fr/institut-adios/centre-val-de-loire" />} />
              <Route path="/fr/institut-adios/corse" element={<Builder resolve="/fr/institut-adios/corse" />} />
              <Route path="/fr/institut-adios/grand-est" element={<Builder resolve="/fr/institut-adios/grand-est" />} />
              <Route path="/fr/institut-adios/hauts-de-france" element={<Builder resolve="/fr/institut-adios/hauts-de-france" />} />
              <Route path="/fr/institut-adios/ile-de-france" element={<Builder resolve="/fr/institut-adios/ile-de-france" />} />
              <Route path="/fr/institut-adios/normandie" element={<Builder resolve="/fr/institut-adios/normandie" />} />
              <Route path="/fr/institut-adios/nouvelle-aquitaine" element={<Builder resolve="/fr/institut-adios/nouvelle-aquitaine" />} />
              <Route path="/fr/institut-adios/occitanie" element={<Builder resolve="/fr/institut-adios/occitanie" />} />
              <Route path="/fr/institut-adios/pays-de-la-loire" element={<Builder resolve="/fr/institut-adios/pays-de-la-loire" />} />
              <Route path="/fr/institut-adios/provence-alpes-cote-d-azur" element={<Builder resolve="/fr/institut-adios/provence-alpes-cote-d-azur" />} />
              <Route path="/fr/institut-adios/dom-tom" element={<Builder resolve="/fr/institut-adios/dom-tom" />} />
          </Route>

          <Route path="/be/institut-adios/belgique" element={<Builder resolve="/be/institut-adios/belgique" />} />
          <Route path="/ma/institut-adios/maroc" element={<Builder resolve="/ma/institut-adios/maroc" />} />
          <Route path="/ca/institut-adios/canada" element={<Builder resolve="/ca/institut-adios/canada" />} />
          <Route path="/ch/institut-adios/suisse" element={<Builder resolve="/ch/institut-adios/suisse" />} />
          <Route path="/lu/institut-adios/luxembourg" element={<Builder resolve="/lu/institut-adios/luxembourg" />} />
          <Route path="/mc/institut-adios/monaco" element={<Builder resolve="/mc/institut-adios/monaco" />} />
          
          <Route path="/fr/residentiel" element={<Builder_Residentiel resolve="/fr/residentiel" />} />
          <Route path="/be/residentiel" element={<Builder_Residentiel resolve="/be/residentiel" />} />
          <Route path="/ma/residentiel" element={<Builder_Residentiel resolve="/ma/residentiel" />} />
          <Route path="/ca/residentiel" element={<Builder_Residentiel resolve="/ca/residentiel" />} />
          <Route path="/ch/residentiel" element={<Builder_Residentiel resolve="/ch/residentiel" />} />
          <Route path="/lu/residentiel" element={<Builder_Residentiel resolve="/lu/residentiel" />} />
          <Route path="/mc/residentiel" element={<Builder_Residentiel resolve="/mc/residentiel" />} />

        </Routes>
      </BrowserRouter>
  </Suspense>
}

/*
>> FOR SITEMAP ...
rdv.addictologue.com/fr/institut-adios/auvergne-rhone-alpes
rdv.addictologue.com/fr/institut-adios/bourgogne-franche-comte
rdv.addictologue.com/fr/institut-adios/bretagne
rdv.addictologue.com/fr/institut-adios/centre-val-de-loire
rdv.addictologue.com/fr/institut-adios/corse
rdv.addictologue.com/fr/institut-adios/grand-est
rdv.addictologue.com/fr/institut-adios/hauts-de-france
rdv.addictologue.com/fr/institut-adios/ile-de-france
rdv.addictologue.com/fr/institut-adios/normandie
rdv.addictologue.com/fr/institut-adios/nouvelle-aquitaine
rdv.addictologue.com/fr/institut-adios/occitanie
rdv.addictologue.com/fr/institut-adios/pays-de-la-loire
rdv.addictologue.com/fr/institut-adios/provence-alpes-cote-d-azur
rdv.addictologue.com/fr/institut-adios/be/institut-adios/belgium
rdv.addictologue.com/fr/institut-adios/ma/institut-adios/maroc
rdv.addictologue.com/fr/institut-adios/ca/institut-adios/quebec
rdv.addictologue.com/fr/institut-adios/ch/institut-adios/suisse
rdv.addictologue.com/fr/institut-adios/lu/institut-adios/luxembourg
*/
